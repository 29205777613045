var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "newSelect", staticClass: "SELECT box-show-1" }, [
    _c("div", { staticClass: "listCont customScrollbar" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.search,
              expression: "search",
            },
          ],
          staticClass: "searchCont",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("div", { staticClass: "cont" }, [
            _c("img", {
              staticClass: "icon_search",
              attrs: {
                src: require("@/assets/img/08-Device-check/icon-camera-blue.svg"),
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchText,
                  expression: "searchText",
                },
              ],
              staticClass: "input_search",
              attrs: { type: "text" },
              domProps: { value: _vm.searchText },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchText = $event.target.value
                },
              },
            }),
          ]),
        ]
      ),
      _vm.listData.length
        ? _c(
            "div",
            { staticClass: "list" },
            _vm._l(_vm.listData, function (list, index) {
              return _c(
                "p",
                {
                  key: index,
                  staticClass: "font_zero fontWeight_medium",
                  class: { active: _vm.activeIndex == index },
                  style: _vm.style_item(_vm.align),
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.selectClick(list, index)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(list.value) + " ")]
              )
            }),
            0
          )
        : _c("div", { staticClass: "nodata font_zero" }, [_vm._v("NO DATA")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }